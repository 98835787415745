import { axios } from 'lib';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  queryKey: ['file-preview'],
  queryOptions: {},
};

const previewFile = async (tenderID, fileId, companyID, tcID = null) => { 
  const url = tcID ? `/api/tenders/${tenderID}/suppliers/${tcID}/files/${fileId}` : `/api/tenders/${tenderID}/files/${fileId}`;
  const {
    data: { data },
  } = await axios.get(url, {headers: {"company_id": companyID}});

  return data;
};

export const useFilePreview = ({tenderID, fileId, tcID = null, queryParams = {}}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return useQuery([...queryKey, fileId], () => previewFile(tenderID, fileId, user.Company.company_id, tcID), {
    refetchInterval: 5 * 60 * 1000,
    initialData: { url: '', name: '', file_ext: '', mime_type: '' },
    enabled: !!fileId,
    select: ({ url, name, file_ext: FileExt, mime_type: MimeType }) => ({ url, name, FileExt, MimeType }),
    ...queryOptions,
  });
};
