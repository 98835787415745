import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getValidateRefNumber = async (queryStringParams) => { 
  const queryString = qs.stringify({ ...queryStringParams });  
  
  const {
    data: { refNumberValid },
  } = await axios.get(
    `/api/tenders/validate_reference_number?${queryString}`,
  );

  return refNumberValid;
};

const defaultQueryParams = {
  refNumber: '',
  mainBuyerID: 0,
  queryOptions: {},
  queryKey: ['validate-ref-number'],
};

export const useValidateRefNumber = (queryParams = {}) => {
  const { queryKey, refNumber, mainBuyerID, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    ref_number: refNumber,
    main_buyer_id: mainBuyerID,
  };  

  return useQuery([...queryKey, refNumber],
    () => getValidateRefNumber(queryStringParams),
    {
      select: ( refNumberValid ) => refNumberValid,
      initialData: true,
      ...queryOptions,
    }
  );
}