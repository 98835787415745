import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const removeUser = ({ userID }) => {
  return axios.delete(`/api/contacts/${userID}`);
};

export const useRemoveUser = (onUserRemoval) => {
  const { enqueueSnackbar } = useSnackbar();
  const snackMessage = 'User successfully removed from the company';

  return useMutation({
    mutationFn: removeUser,
    onSuccess: () => {
      enqueueSnackbar(snackMessage, {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      onUserRemoval();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
