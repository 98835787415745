import { axiosAuth } from "./axios"

export const validateVAT = async (countryCode, vat) => {
  if (countryCode === "SE") {
    vat = `${vat}01`;
  }

  const body = {
    countryCode: countryCode,
    vatNumber: vat
  }

  const resp = await axiosAuth.post("/validateVAT", body)
  // return resp.data.isVATNumberValid;
  return  true;
}