import React from 'react';
import { ReactComponent as PDFIcon } from 'assets/file-pdf-regular.svg';

import {
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  CheckIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  HomeIcon,
  LifebuoyIcon,
  UserIcon,
  StarIcon,
  PlusCircleIcon,
  XMarkIcon,
  LockClosedIcon,
  ArrowsRightLeftIcon,
  MagnifyingGlassIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  MinusIcon,
  PhotoIcon,
  FolderArrowDownIcon,
} from '@heroicons/react/24/solid';

import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ArchiveBoxIcon,
  BuildingStorefrontIcon,
  BuildingOfficeIcon,
  BuildingOffice2Icon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  DocumentCheckIcon,
  ClipboardDocumentListIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  PencilSquareIcon,
  UsersIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';

import { Box } from '@mui/material';

const iconMap = {
  logOut: <ArrowRightStartOnRectangleIcon />,
  cog8Tooth: <Cog8ToothIcon />,
  lifebuoy: <LifebuoyIcon />,
  home: <HomeIcon />,
  star: <StarIcon />,
  chevronUp: <ChevronUpIcon />,
  chevronRight: <ChevronRightIcon />,
  chevronLeft: <ChevronLeftIcon />,
  chevronDown: <ChevronDownIcon />,
  download: <ArrowDownTrayIcon />,
  downloadFile: <FolderArrowDownIcon />,
  edit: <PencilSquareIcon />,
  user: <UserIcon />,
  users: <UsersIcon />,
  circlePlus: <PlusCircleIcon />,
  plus: <PlusIcon />,
  minus: <MinusIcon />,
  xMark: <XMarkIcon />,
  lockClosed: <LockClosedIcon />,
  info: <InformationCircleIcon />,
  warning: <ExclamationTriangleIcon />,
  archive: <ArchiveBoxIcon />,
  marketplace: <BuildingStorefrontIcon />,
  menu: <ArrowsRightLeftIcon />,
  buildingOffice: <BuildingOfficeIcon />,
  buildingOffice2: <BuildingOffice2Icon />,
  documentDuplicate: <DocumentDuplicateIcon />,
  documentText: <DocumentTextIcon />,
  documentCheck: <DocumentCheckIcon />,
  clipboardDocumentList: <ClipboardDocumentListIcon />,
  search: <MagnifyingGlassIcon />,
  elipsisVertical: <EllipsisVerticalIcon />,
  trash: <TrashIcon />,
  photo: <PhotoIcon />,
  confirm: <CheckIcon />,
  pdf: <PDFIcon />,
  subscription: <CreditCardIcon />,
};

const defaultIconStyling = ({ palette }) => ({
  height: 24,
  svg: {
    height: 24,
    width: 24,
    stroke: palette.grey[300],
    color: palette.grey[300],
    strokeWidth: 1,
    verticalAlign: 'middle',
  },
});

export const IconWrapper = ({ icon = '', sx = {} }) => {
  return icon ? (
    <Box sx={defaultIconStyling}>
      <Box sx={sx}>{iconMap[icon]}</Box>
    </Box>
  ) : (
    <></>
  );
};
