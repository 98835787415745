import { axios } from 'lib';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

const updateUser = async (id, { phoneNumber, firstName, lastName, visible }) => {
  return axios.put(
    `/api/contacts/${id}`,
    JSON.stringify({
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      visible
    })
  );
};

export const useUpdateUserById = (id) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (user) => updateUser(id, user),
    onSuccess: () => {
      enqueueSnackbar('User was successfully updated', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
