import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';

import { InputWrapper } from './InputWrapper';

export const AsyncSelect = ({
  name = '',
  label = '',
  fullWidth = false,
  wrapperSx = {},
  placeholder = '',
  onChange,
  ItemCard,
  noOptionFound = 'No items found',
  handleNotFoundButtonClick = () => null,
  queryFunction,
  optionLabelName,
  error = null,
  required = false,
  disabled = false,
  addShadowBuyer,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { data, refetch } = useQuery([name, inputValue], () => queryFunction(inputValue), {
    enabled: !!inputValue,
    initialData: { data: [] },
    select: ({ data }) => {
      const transformedData = data.map((item) => ({
        ...item,
        label:
          typeof item[optionLabelName] === 'number'
            ? item[optionLabelName].toString()
            : item[optionLabelName],
      }));

      if (transformedData.length  === 0) {
        return transformedData;
      }

      // Add "Add shadow buyer" at the end of the results
      return [
        ...transformedData,
        { optionLabelName: noOptionFound, isCustom: true },
      ];
    },
    onSettled: () => {
      setLoading(() => false);
      // setOpen(true);
    },
  });

  const debouncedSetInputValue = useDebouncedCallback(
    (newInputValue) => setInputValue(() => newInputValue),
    300
  );
  useEffect(() => {
    if (inputValue) refetch();
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <InputWrapper required={required} fullWidth={fullWidth} wrapperSx={wrapperSx} label={label} error={!!error}>
      <Autocomplete
        disabled={disabled}
        open={open && inputValue.length > 0}
        onFocus={() => setOpen(true)}
        placeholder={placeholder}
        fullWidth={true}
        blurOnSelect={true}
        options={data}
        getOptionLabel={(item) => `${item.Name} (${item.VAT})`}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          event.preventDefault();
          onChange(newValue);
          setOpen(false);
        }}
        onInputChange={(_, newInputValue) => {
          setLoading(() => !!newInputValue);
          debouncedSetInputValue(newInputValue);
        }}
        noOptionsText={
          inputValue ? (
            <Box onClick={() => setOpen(false)}>{noOptionFound}</Box>
          ) : (
            'Please enter a search parameter'
          )
        }
        // noOptionsText={
        //   <AddButton fullWidth onClick={handleNotFoundButtonClick}>
        //     Create shadow profile
        //   </AddButton>
        // }
        // renderOption={(props, option) => <ItemCard {...{ ...props, ...option, isCustom: option.isCustom, setOpen }} />}
        renderOption={(props, option) => (
          option.isCustom ? (
            <Box onClick={() => setOpen(false)}>{addShadowBuyer}</Box>
          ) : (
            <ItemCard {...{ ...props, ...option }} />
          )
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={() => setOpen(false)}
            placeholder={placeholder}
            helperText={error ? error?.message : ''}
            error={!!error}
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="primary" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              // '& > *': { height: '47px !important' },
              '.MuiInputBase-root': { backgroundColor: 'white', py: 0, px: 0 },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            p: 0,
            boxShadow: 25,
            borderWidth: 2,
            '& > *': {
              p: '16px !important',
              bgcolor: 'white !important',
            },
            '& > *:hover': { bgcolor: 'white !important' },
            '& > :not(:last-child)': {
              borderBottom: '2px solid',
              borderColor: 'border.main',
            },
          },
        }}
      />
    </InputWrapper>
  );
};
