import React from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { FormProvider, TextField } from 'components/Form';

import { Modal } from 'components/Modal';
import { AsyncSelect } from 'components/Form';
import { getCompanies } from '../api/getCompanies';
import { ImageAvatar } from 'components/Avatar';

const ItemCard = ({
  Name: name,
  VAT: vatNumber,
  Description: description,
  companyType,
  square_logo_url,
  ...rest
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{ img: { borderRadius: 1, width: 20, height: 20 } }}
      {...rest}
    >
      <ImageAvatar img={square_logo_url || ''} name={name} searchResult />
      <Typography variant="body1" fontWeight="bold">
        {name} ({vatNumber})
      </Typography>
    </Stack>
  );
};

export const MainInfo = ({
  handleCloseModal,
  methods,
  onSubmit,
  open,
  handleMainBuyerSelect,
  NoOptionsFoundButton,
  children,
  mainBuyerDisabled,
}) => {
  return (
    <Modal title="Add DPS" handleClose={handleCloseModal} open={open}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack>
          <TextField
            name="dpsName"
            label="DPS Name"
            placeholder="Please enter DPS name"
            required={true}
            type="text"
            sx={{ mb: 2 }}
            fullWidth
          />

          <AsyncSelect
            error={methods.formState.errors?.mainBuyer}
            placeholder="Please select main buyer"
            wrapperSx={{ mb: 2 }}
            name="mainBuyer"
            label="Main Buyer"
            fullWidth
            onChange={handleMainBuyerSelect}
            queryFunction={(searchTerm) => getCompanies('buyer', searchTerm)}
            optionLabelName={'Name'}
            ItemCard={(props) => <ItemCard companyType={'buyer'} {...props} />}
            //@ts-ignore
            noOptionFound={<NoOptionsFoundButton />}
            addShadowBuyer={<NoOptionsFoundButton color="secondary" sx ={({ palette }) => ({ border: `1px solid ${palette.grey[200]}`})} />}
            required
            disabled={mainBuyerDisabled}
          />
          <TextField
            name="refNumber"
            label="Reference Number"
            placeholder="Please enter reference number"
            required={true}
            type="text"
            sx={{ mb: 2 }}
            fullWidth
            error={Boolean(methods?.formState?.errors?.refNumber)}
            helperText={methods?.formState?.errors?.refNumber?.message}
          />
          {children}

          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 0.5 }}
          >
            Add DPS
          </Button>
        </Stack>
      </FormProvider>
    </Modal>
  );
};
