import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import MuiTextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { InputWrapper } from './InputWrapper';
import { Link as RouterLink } from 'react-router-dom';

export const TextField = ({
  name,
  helperText = '',
  type = 'text',
  label = '',
  fullWidth = false,
  labelSx = {},
  wrapperSx = {},
  required = false,
  url = '',
  ...other
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputWrapper
          error={!!error}
          label={label}
          fullWidth={fullWidth}
          required={required}
          labelSx={labelSx}
          wrapperSx={wrapperSx}
        >
          {url.length > 0 ? (
            <Link
              component={RouterLink}
              to={url}
              color="inherit"
            >
              <MuiTextField
                {...field}
                fullWidth
                type={type}
                value={type === 'number' && field.value === 0 ? '' : field.value}
                onChange={(event) => {
                  if (type === 'number') {
                    field.onChange(Number(event.target.value));
                  } else {
                    field.onChange(event.target.value);
                  }
                }}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...other}
                autoComplete="new-password"
                InputProps={{
                  style: { pointerEvents: "none", textDecoration: 'underline' },
                }}
              />
            </Link>
          ) : (
            <MuiTextField
              {...field}
              fullWidth
              type={type}
              value={type === 'number' && field.value === 0 ? '' : field.value}
              onChange={(event) => {
                if (type === 'number') {
                  field.onChange(Number(event.target.value));
                } else {
                  field.onChange(event.target.value);
                }
              }}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...other}
              autoComplete="new-password"
            />
          )}
        </InputWrapper>
      )}
    />
  );
};
