import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  mainBuyerID: '',
  queryKey: ['tender-framework-agreements'],
  queryOptions: {},
};

const getTenderFrameworkAgreements = async (tenderID, queryStringParams, companyID) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: agreements },
  } = await axios.get(`/api/tenders/${tenderID}/framework_agreements?${queryString}`, {headers: {"company_id": companyID}});

  return { agreements };
};

export const useTenderFrameworkAgreements = (tenderID, queryParams = {}) => {
  const { user } = useAuth();
  const { mainBuyerID, queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    mainbuyerid: mainBuyerID,
  };

  return useQuery(
    [...queryKey, tenderID],
    () => getTenderFrameworkAgreements(tenderID, queryStringParams, user.Company.company_id),
    {
      select: ({ agreements }) => ({ agreements }),
      initialData: { agreements: [] },
      ...queryOptions,
    }
  );
};
