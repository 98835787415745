import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateUserVisibility } from '../api/updateUserVisibility';
import { AccordionListItem } from 'components/Button';
import { Button, Stack, Typography } from '@mui/material';
import { Modal } from 'components/Modal';

export const VisibleToggleButton = ({ visible, refetch }) => {
  const { id } = useParams();
  const { mutateAsync } = useUpdateUserVisibility(refetch);

  const [open, setOpen] = useState(false);

  const handleCloseModal = () => setOpen(false);

  const handleStatusUpdate = () =>
    mutateAsync({
      userID: id,
      visible: visible === true ? false : true,
    })
      .then(handleCloseModal)
      .catch(() => {});

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>
        {visible === true? 'Hide' : 'Show'}
      </AccordionListItem>

      <Modal open={open} handleClose={handleCloseModal} title="Visibility update">
        <Typography variant="body1" fontWeight={500}>
          Are you sure you want to set users visibility to{' '}
          <span style={{ fontWeight: 'bold' }}>
            {visible === true ? 'hidden' : 'visible'}?{' '}
          </span>
        </Typography>

        <Stack direction="row" justifyContent="center" gap={1}>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleStatusUpdate}>
            Confirm
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
