import { axios } from 'lib/axios';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/auth';


const createShadowBuyer = async (data) => {
  data.VAT = `SE${data.VAT}01`;
  const { data: shadowBuyer } = await axios.post('/api/companies', {
    ...data,
    Type: 'buyer',
    Country: 'sweden',
    is_shadow: true,
  });

  return shadowBuyer;
};

const createRequest = async (values) => {
  if (values.mainBuyer) {
    const jsonData = {
      main_buyer_id: Number(values.mainBuyer),
      fa_name: values.faName,
      description: values.description,
      ref_number: values.refNumber,
      start_date: values.startDate,
      end_date: values.endDate,
      status: values?.status,
      more_info_url: values.moreInfoURL,
      linked_tender_id: values?.linkedTenderID,
    };

    return axios.post('/api/framework_agreements', JSON.stringify(jsonData));
  }

  const { mainBuyerName: Name, VAT, last_updated_user_id } = values;
  const shadowBuyer = await createShadowBuyer({ Name, VAT, last_updated_user_id });

  const data = {
    fa_name: values.faName,
    description: values.description,
    ref_number: values.refNumber,
    start_date: values.startDate,
    end_date: values.endDate,
    status: values?.status,
    main_buyer_id: Number(shadowBuyer.company_id),
    more_info_url: values.moreInfoURL,
    linked_tender_id: values?.linkedTenderID,
  };

  return axios.post('/api/framework_agreements', JSON.stringify(data));
};

export const useCreateFrameworkAgreement = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  return useMutation({
    mutationFn: (data) => createRequest({last_updated_user_id: user.user_id, ...data}),
    onSuccess: () => {
      enqueueSnackbar('Framework Agreement created successfully', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
