import React, { useEffect, useState } from 'react';
import qs from 'qs';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';

import { ContentLayout } from 'components/Layout';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { paths } from 'routes';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { useParams } from 'react-router-dom';
import { Search } from 'components/Search';
import { AddCompany } from '../components/AddCompany';
import { useDebouncedCallback } from 'use-debounce';
import { useConsortiumGroupSuppliers } from '../api/getConsortiumGroupSuppliers';
import { Pagination } from 'components/Pagination';
import { NotFound } from 'components/NotFound/NotFound';
import { SupplierListItem } from '../components/SupplierListItem';
import { useRemoveConsortiumGroupSupplier } from '../api/removeConsortiumGroupSupplier';
import { AccordionButton } from 'components/Button';
import { useConsortiumGroup } from '../api/getConsortiumGroupById';
import { SupplierConsortiumForm } from '../components/SupplierConsortiumFormNew';
import { useTender } from '../api/getTenderById';
import { useAuth } from 'lib/auth';
import { Loader } from 'components/Loader';

export const ConsortiumGroupSuppliers = () => {
  const { account } = useAuth();
  const { id: tenderID, cgId } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const { data: consortiumGroup, refetch: refetchConsortiumGroup, isFetching: isFetching1 } = useConsortiumGroup(tenderID, cgId);
  const { data: tender, isFetching: isFetching2 } = useTender(tenderID);

  const {
    data: { suppliers, total },
    refetch: refetchSuppliers,
    isFetching: isFetching3,
  } = useConsortiumGroupSuppliers(tenderID, cgId, {
    search,
    includeInactive,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      }
    },
  });

  const isFetching = isFetching1 || isFetching2 || isFetching3;

  const handlePageChange = (_, page) => setPage(page);
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const onSupplierRemoval = () => {
    refetchSuppliers();
    setMarkedForDelete(null);
  };

  const { mutateAsync: removeConsortiumGroupSupplier } =
    useRemoveConsortiumGroupSupplier(onSupplierRemoval);

  useRefetch();

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  return (
    <ContentLayout>
      <Stack height="100%">
        {!isFetching && !loading && (
          <BreadCrumbs
            items={[
              { name: 'Tenders', to: paths.tenders },
              {
                name: tender?.tender_name ?? 'Details View',
                to: `${paths.tenders}${tenderID}`,
              },
              {
                name: 'Bidders',
                to: `${paths.tenders}${tenderID}?${qs.stringify({ tab: 'suppliers' })}`,
              },
              { name: `${consortiumGroup?.cg_name} - Bidders` },
            ]}
          />
        )}

        {account.super_admin && (
          <Stack direction="row" height={75} alignItems="center" justifyContent="flex-end">
            <AccordionButton label="Actions" sx={{ width: 130 }}>
               <SupplierConsortiumForm refetch={refetchConsortiumGroup} />
            </AccordionButton>
          </Stack>
        )}

        <Stack
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={3}
          height="100%"
        >
          {account.super_admin && (
            <DeleteConfirmationModal
              title="Remove Bidder"
              markedForDelete={markedForDelete}
              mutateAsync={() =>
                removeConsortiumGroupSupplier({ tenderID, cgId, cgcId: markedForDelete?.itemId })
              }
            >
              <Typography variant="body1" fontWeight={500}>
                Please confirm the removal of the bidder{' '}
                <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
              </Typography>
            </DeleteConfirmationModal>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            width={1}
          >
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 350 }} />
            {account.super_admin && (
              <>
                <FormGroup sx={{ flex: '1 1 300px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={includeInactive}
                        onChange={handleIncludeInactiveChange}
                      />
                    }
                    label="Include inactive"
                  />
                </FormGroup>
                <Box sx={{minWidth: 150, width: 200}}>
                  <AddCompany type="supplier" refetch={refetchSuppliers} alreadyAdded={suppliers} label="bidder" />
                </Box>
              </>
            )}
          </Stack>

          <Divider />

          {isFetching && !loading ? 
          (
            <></>
          ) :
          (!isFetching || (isFetching && loading)) ?
          (
            <>
            {loading && (
              <Loader />
            )}

            {!loading && (
            <>
              <Stack gap={1} flex={1}>
                {suppliers?.length === 0 && (
                  <NotFound
                    title="No Suppliers found"
                    caption={
                      search
                        ? 'Please try another search term'
                        : account.super_admin
                        ? 'Click the "Add supplier" button to begin'
                        : ''
                    }
                    wrapperSx={{ mt: 19 }}
                  />
                )}

                {suppliers?.length !== 0 &&
                  suppliers?.map(({ company: supplier, tcgc_id }, index) => (
                    <SupplierListItem
                      key={supplier.Name + index}
                      markForDelete={() => setMarkedForDelete({ name: supplier.Name, itemId: tcgc_id })}
                      tender_id = {tenderID}
                      tcID = {tcgc_id}
                      show_files={false}
                      {...supplier}
                    />
                  ))}
              </Stack>

              <Stack direction="row" justifyContent="flex-end">
                <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
              </Stack>
              </>
            )}
          </>
          ) :
          (
            <></>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useRefetch() {
    useEffect(() => {
      refetchSuppliers();
    }, [page, includeInactive, search]);
  }
};

/*

  /fa/id/suppliers (search, limit, offset, total za pagination; count sa details view-a za broj u tabu)

*/
