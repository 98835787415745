import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const createShadowCompany = async (data) => {
  data.VAT = `SE${data.VAT}01`;
  const { data: shadowCompany } = await axios.post('/api/companies', { ...data, is_shadow: true });

  return shadowCompany;
};

export const useCreateShadowCompany = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: createShadowCompany,
    onSuccess: (data) => {
      enqueueSnackbar(`Shadow ${data.Type} created successfuly`, { variant: 'success' });
    },
    onError: (error) => {
      if (!error?.response) {
        enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
      }
    },
  });
};
