import React from 'react';
import { FormControlLabel, FormGroup, Switch as MuiSwitch, Tooltip, Stack } from '@mui/material';
import { useAuth } from 'lib/auth';
import { ReactComponent as LockIcon } from 'assets/LockClosed.svg';

const SwitchWrapper = ({
  disabled,
  onChange,
  checked,
  name,
  label,
  sx={},
  ...other
 }) => {
  return (
    <FormGroup sx={{
      flex: '1',
      alignItems:"baseline",
      '& .MuiFormControlLabel-root': {
        m:0,
        alignItems: "baseline",
      },
      '& .MuiFormControlLabel-label': {
        fontSize:"0.75rem",
        mb:0.5,
      },
    }}>
      <FormControlLabel
        control={
          <MuiSwitch
            sx={({palette}) => ({
              width: 52,
              height: 28,
              padding: 0,
              display: 'flex',
              '& .MuiSwitch-switchBase': {
                padding: "2px",
                color: '#fff !important',
                '&.Mui-checked': {
                  transform: 'translateX(24px)',
                  '& + .MuiSwitch-track': {
                    backgroundColor: palette.primary.main,
                    opacity: disabled ? 0.5 : 1,
                  },
                },
              },
              '& .MuiSwitch-thumb': {
                width: 24,
                height: 24,
                boxShadow: 'none',
              },
              '& .MuiSwitch-track': {
                borderRadius: 14,
                backgroundColor: palette.grey[400],
                opacity: 1,
              },
              ...sx,
            })}
            name={name}
            disabled={disabled}
            onChange={onChange}
            checked={checked}
            {...other}
          />
        }
        label={label}
        labelPlacement="top"
      />
    </FormGroup>
  );
};

export const Switch = ({
  disabled,
  onChange,
  checked,
  name,
  label,
  sx={},
  subscriptionTypes,
  tooltip,
  ...other
}) => {
  const {
    account: { super_admin },
    user
  } = useAuth();

  if (subscriptionTypes.length && !subscriptionTypes.includes(user.Company.subscription_level) && !super_admin) {
    return (
      <Tooltip
        arrow
        title={tooltip}
        placement="top"
        slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
      >
        <Stack direction="row" alignItems="center">
          <SwitchWrapper disabled name={name} label={label} onChange={onChange} checked={checked} sx={sx} other={other} />
          <Stack
            sx={{
              pl:1,
              opacity: 0.38,
            }}

            flex={1}
          >
            <LockIcon />
          </Stack>
        </Stack>
      </Tooltip>
    );
  }

  return <SwitchWrapper disabled={disabled} name={name} label={label} onChange={onChange} checked={checked} sx={sx} other={other} />;
};
