import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useYupValidationResolver from 'hook/useYupValidationResolver';

import { ShadowCompany } from 'features/companies/components/ShadowCompany';

import { AddButton } from 'components/Button';
import { ListCard, PaperCard } from 'components/Card';
import { CardAutocomplete, FormProvider, TextField } from 'components/Form';
import { IconWrapper } from 'components/Icon';

import { getCompanies } from '../api/getCompanies';
import { useFrameworkAgreement } from '../api/getFrameworkAgreementById';
import { useAddCompaniesToFrameworkAgreement } from '../api/addCompanyToFrameworkAgreement';
import { supplierConsortiumSchema } from '../validation';
import { useCreateConsortiumGroup } from '../api/createConsortiumGroup';
import { ImageAvatar } from 'components/Avatar';

const ItemCard = ({
  Name: name,
  VAT: vatNumber,
  Description: description,
  companyType,
  square_logo_url,
  alreadySelected,
  alreadyAdded,
  isConsortiumGroup,
  company_id,
  ...rest
}) => {
  if (!isConsortiumGroup && alreadyAdded.some(item => item.company_id === company_id)) {
    return null;
  }

  if (alreadySelected.some(item => item.company_id === company_id)) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{ img: { borderRadius: 1, width: 20, height: 20 } }}
      {...rest}
    >
      <ImageAvatar img={square_logo_url || ''} name={name} searchResult />
      <Typography variant="body1" fontWeight="bold">
        {name} ({vatNumber})
      </Typography>
    </Stack>
  );
};
const CustomEndIcon = () => (
  <IconWrapper
    icon="plus"
    sx={{
      height: 24,
      svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
    }}
  />
);

export const AddSuppliersForm = ({ refetch, alreadyAdded }) => {
  const { id: faId } = useParams();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isConsortiumGroup, setIsConsortiumGroup] = useState(false);

  const methods = useForm({
    defaultValues: {
      cg_name: '',
    },
    resolver: useYupValidationResolver(
      isConsortiumGroup ? supplierConsortiumSchema : yup.object().shape({})
    ),
  });

  const { data: frameworkAgreement } = useFrameworkAgreement(faId);

  const { mutateAsync: updateFrameworkAgreementCompanies } = useAddCompaniesToFrameworkAgreement();
  const { mutateAsync: createConsortiumGroup } = useCreateConsortiumGroup();

  const handleOpenModal = useCallback(() => setModalOpen(true), []);
  const handleCloseModal = useCallback(() => setModalOpen(false), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setSelected([]);
  }, []);

  const handleIsConsortiumGroupChange = (_, isConsortiumGroup) => {
    if (!isConsortiumGroup)
      methods.reset({ cg_name: '' }, { keepDirty: false, keepErrors: false, keepIsValid: false });

    setIsConsortiumGroup(isConsortiumGroup);
  };

  const onUpdate = () => {
    refetch();
    handleClose();
  };

  const onSubmit = async ({ cg_name }) => {
    if (!isConsortiumGroup)
      updateFrameworkAgreementCompanies({
        fa_id: faId,
        companies_ids: selected.map(({ company_id }) => +company_id),
        company_type: 'supplier',
      })
        .then(onUpdate)
        .catch(() => null);
    else {
      createConsortiumGroup({
        fa_id: faId,
        cg_name,
        companies_ids: selected.map(({ company_id }) => +company_id),
      })
        .then(onUpdate)
        .catch(() => null);
    }
  };

  const removeSelected = (id) =>
    setSelected((selected) => selected.filter(({ company_id }) => company_id !== id));

  const addCompany = (value) => {
    if (value) setSelected((previousSelected) => [...previousSelected, value]);
  };

  const addShadowSupplier = (shadowSupplier) => {
    setSelected((previousSelected) => [...previousSelected, shadowSupplier]);
  };

  return (
    <>
      <AddButton
        fullWidth
        onClick={() => setOpen(true)}
        sx ={({ palette }) => ({
          svg: { mt: -0.5 },
          minWidth: 200,
          border: `1px solid ${palette.grey[200]}`,
        })}
        color='secondary'
      >
        Add Suppliers
      </AddButton>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          display: open ? 'block' : 'none',
          zIndex: 40,
        }}
      >
        <ShadowCompany
          open={modalOpen}
          handleClose={handleCloseModal}
          addToList={addShadowSupplier}
          type="supplier"
        />

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack width={1} height={1}>
            <PaperCard
              sx={{
                background: '#fff',
                width: '100%',
                py: 4,
                px: 8,
                border: 0,
                borderRadius: 0,
                boxShadow: 26,
                display: 'flex',
                boxSizing: 'border-box',
              }}
            >
              <Stack direction="row" width={1} alignItems="center" justifyContent="space-between">
                <Typography variant="h3">
                  Add suppliers to ‘{frameworkAgreement?.fa_name || 'the framework agreement'}’
                </Typography>
                <Stack direction="row" gap={2}>
                  <Button
                    size="medium"
                    onClick={handleClose}
                    variant="contained"
                    sx={({ palette }) => ({
                      width: 82,
                      boxShadow: 25,
                      color: palette.secondary.contrastText,
                      backgroundColor: '#fff',
                      ":hover" : {
                        backgroundColor: palette.secondary.dark,
                      }
                    })}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!selected.length}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 67 }}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </PaperCard>

            <Stack direction="row" flex={1}>
              <Stack mt={7} px={8} gap={3} flex={1}>
                <Stack gap={3}>
                  <Typography variant="h2">Consortium group</Typography>
                  <Stack gap={0}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={isConsortiumGroup}
                            onChange={handleIsConsortiumGroupChange}
                          />
                        }
                        label="Adding new consortium group?"
                      />
                    </FormGroup>

                    <TextField
                      disabled={!isConsortiumGroup}
                      name="cg_name"
                      label="Consortium Group Name"
                      placeholder="Please enter Consortium Group name"
                      required={true}
                      type="text"
                      sx={{ mb: 2 }}
                      fullWidth
                    />
                  </Stack>
                </Stack>

                <Typography variant="h2">Select suppliers</Typography>
                <CardAutocomplete
                  label={`Existing suppliers`}
                  fullWidth
                  onChange={addCompany}
                  queryFunction={(searchTerm) => getCompanies('supplier', searchTerm)}
                  ItemCard={(props) => <ItemCard companyType={'supplier'} {...props} isConsortiumGroup={isConsortiumGroup} alreadyAdded={alreadyAdded} alreadySelected={selected} />}
                  handleNotFoundButtonClick={handleOpenModal}
                  optionLabelName="company_id"
                />

                <AddButton color="secondary" onClick={handleOpenModal}>Create Shadow Supplier</AddButton>
              </Stack>

              <Divider orientation="vertical" flexItem sx={{ borderColor: 'grey.500' }} />

              <Stack mt={7} gap={3} flex={1} px={8}>
                <Typography variant="h2">
                  Added suppliers {selected.length ? `(${selected.length})` : ''}
                </Typography>

                {selected.length !== 0 && (
                  <Stack gap={1}>
                    <Typography>These suppliers can shop from this Agreement</Typography>

                    {selected.map(
                      ({ Name: name, VAT: vatNumber, Type: type, company_id }, index) => (
                        <ListCard key={index} sx={{py:1}}>
                          <Stack flex={1} direction="row">
                            <Stack flex={4} alignItems="flex-start">
                              <Typography variant="body1" fontWeight="bold">
                                {name}
                              </Typography>
                              <Stack direction="row" gap={1}>
                                <Typography variant="caption" fontWeight={600}>
                                  {vatNumber}
                                </Typography>
                              </Stack>
                            </Stack>
                            <IconButton
                              onClick={() => removeSelected(company_id)}
                              sx={{
                                "&:hover" : {
                                  backgroundColor: "#fff",
                                },
                              }}
                            >
                              <IconWrapper
                                icon="trash"
                                sx={({ palette }) => ({
                                  svg: {
                                    width: 24,
                                    height: 24,
                                    mt:-1.5,
                                    stroke: palette.grey[500],
                                    color: palette.grey[500],
                                  },
                                })}
                              />
                            </IconButton>
                          </Stack>
                        </ListCard>
                      )
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      </Box>
    </>
  );
};
