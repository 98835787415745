import React, { useEffect } from 'react';

import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ListCard } from 'components/Card';

import { isoToDateString } from 'utils/dateFormatter';

import { useAuth } from 'lib/auth';

export const SubscriptionsListItem = ({
  id,
  company_id,
  user_id,
  subscription_level,
  number_of_users,
  promo_code,
  created_on,
  companyType,
  Company,
  User
}) => {
  const { account } = useAuth();

  return (
    <ListCard>
      {account.super_admin && (
        <>
          <Stack justifyContent="center" flex={1.7}>
            <Typography variant="caption" fontWeight={700}>
              Date and time
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(created_on, true)}
            </Typography>
          </Stack>
          <Stack justifyContent="center" flex={1}>
            <Link
              component={RouterLink}
              to={`/app/${Company.Type}s/${company_id.toString()}?tab=framework agreements`}
              underline="none"
              color="inherit"
            >
              <Typography variant="caption" fontWeight={700}>
                Organization
              </Typography>

              <Typography variant="body2" fontWeight={700}>
                {`${Company.Name}`}
              </Typography>
            </Link>
          </Stack>
          <Stack justifyContent="center" flex={0.8}>
            <Typography variant="caption" fontWeight={700}>
              Organiation Type
            </Typography>

            <Typography variant="body2" fontWeight={700} sx={{textTransform: 'capitalize'}}>
            {`${Company.Type}`}
            </Typography>
          </Stack>
          <Stack justifyContent="center" flex={1}>
            <Typography variant="caption" fontWeight={700}>
              User
            </Typography>
            <Typography variant="body2" fontWeight={700} height={0.5}>
              <Link
                component={RouterLink}
                to={`/app/contacts/${user_id.toString()}`}
                underline="none"
                color="inherit"
              >
                {`${User.first_name} ${User.last_name}`}
              </Link>
            </Typography>
            
          </Stack>
          <Stack justifyContent="center" flex={0.7}>
            <Typography variant="caption" fontWeight={700}>
              Subscription
            </Typography>

            <Typography variant="body2" fontWeight={700} sx={{textTransform: 'capitalize'}}>
              {`${subscription_level}`}
            </Typography>
          </Stack>
          <Stack justifyContent="center" flex={0.7}>
            <Typography variant="caption" fontWeight={700}>
              No of users
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {`${number_of_users}`}
            </Typography>
          </Stack>
          <Stack justifyContent="center" flex={0.7}>
            <Typography variant="caption" fontWeight={700}>
              Promo code
            </Typography>

            <Typography variant="body2" fontWeight={700} height={0.5}>
              {`${promo_code}`}
            </Typography>
          </Stack>
        </>
      )}
    </ListCard>
  );
};
