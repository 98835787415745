//@ts-nocheck
import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib/axios';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: false,
  queryKey: ['users'],
  queryOptions: {},
};

const getAllUsers = async (queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: users, total },
  } = await axios.get(`/api/contacts?${queryString}`);
  return {users, total};
};

export const useUsers = (queryParams = {}) => {
  const { queryKey, queryOptions, page, limit, search, includeInactive } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
  };

  return useQuery([...queryKey], () => getAllUsers(queryStringParams), {
    select: ({ users, total }) => ({ users, total }),
    initialData: {
      users: [],
      total: 0,
    },
    ...queryOptions,
  });
};
