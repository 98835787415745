import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const uploadImage = async (userId, name, { file }) => {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('file', file);
  formData.append('file_ext', 'png');
  formData.append('last_updated_user_id', '1');
  formData.append('parent_type', 'company');

  const { data } = await axios.post(`/api/contacts/${userId}/files`, formData);

  return data;
};

export const useProfileImageUpload = (userId, name, onSuccess = () => {}) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    //@ts-ignore
    mutationFn: (data) => uploadImage(userId, name, data),
    onSuccess: () => {
      enqueueSnackbar('Image succesfully uploaded', { variant: 'success' });
      onSuccess();
    },
    onError: (error) => {
      //@ts-ignore
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
