import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography, Box } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';

import { NotFound } from 'components/NotFound/NotFound';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'components/Search';
import { Pagination } from 'components/Pagination';

import { useTenderFiles } from '../api/getTendersFiles';
import { FileListItem } from './FileListItem';
import { downloadFile, donwloadFileFromURL } from '../api/downloadFile';
import { FileUploadModal } from './FileUploadModal';
import { useFilePreview } from '../api/previewFile';
import { PDFViewer } from 'components/PDFViewer/PDFViewer';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { useRemoveFile } from '../api/removeFile';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import { FilesPremiumBanner } from '../../subscriptions/components/FilesPremiumBanner';
import { useTenderFilesCount } from '../api/getTendersFilesCount';
import { Loader } from 'components/Loader';
import { DetailViewTabList } from '../components/TabList';
import { useTenderSupplierFiles } from '../api/getTenderSupplierFiles';
import { useTendersConsortiumGroupFiles } from '../api/getTendersConsortiumGroupFiles';
import { useQueryClient } from 'react-query';

import { useAuth } from 'lib/auth';

const filesTabName = 'files';

export const FilesTab = ({ currentTab, suppliers, suppliersFilesCount, generalFilesCount }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentSubtab = queryParams.get('subtab') || 'general';
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { account, user } = useAuth();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currentFilePreviewId, setCurrentFilePreviewId] = useState(null);
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [markedForEdit, setMarkedForEdit] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [timer, setTimer] = useState(null);
  const [subTab, setSubTab] = useState(currentSubtab);

  const selectedSupplier = suppliers.find(supplier => `${supplier.tc_id}` === subTab);
  const selectedType = selectedSupplier ? selectedSupplier.company_type : 'general';

  const {
    data: { files: generalFiles, total: generalFilesTotal },
    refetch: refetchGeneralFiles,
    isFetching: isFetching1,
  } = useTenderFiles(id, {
    search,
    page: page - 1,
    queryOptions: { 
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoadingFiles(false), 1000);
      },
      enabled: currentTab === filesTabName,
    },
  });
  const {
    data: { url, name, MimeType },
  } = useFilePreview({tenderID: id, fileId: currentFilePreviewId, tcID: selectedType !== 'general' ? selectedSupplier?.tc_id : null});

  const {
    //@ts-ignore
    refetch: refetchGeneralFilesCount,
  } = useTenderFilesCount(id);

  const {
    data: { files: supplierFiles, total: supplierFilesTotal },
    refetch: refetchSupplierFiles,
    isFetching: isFetching2,
  } = useTenderSupplierFiles(id, subTab, {
    search,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoadingFiles(false), 1000);
      },
      enabled: (currentTab === filesTabName && selectedSupplier && subTab === `${selectedSupplier.tc_id}` && selectedType === 'supplier'),
    },
  });

  const {
    data: { files: groupFiles, total: groupFilesTotal },
    refetch: refetchGroupFiles,
    isFetching: isFetching3,
  } = useTendersConsortiumGroupFiles(id, subTab, {
    search,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoadingFiles(false), 1000);
      },
      enabled: (currentTab === filesTabName && selectedSupplier && subTab === `${selectedSupplier.tc_id}` && selectedType === 'group'),
    },
  });

  const isFetchingFiles = isFetching1 || isFetching2 || isFetching3;

  const files = subTab === 'general' ? generalFiles : selectedType === 'supplier' ? supplierFiles : groupFiles;
  const total = subTab === 'general' ? generalFilesTotal : selectedType === 'supplier' ? supplierFilesTotal : groupFilesTotal;

  const handlePageChange = (_, page) => setPage(page);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const onFileRemoval = () => {
    switch (selectedType) {
      case 'supplier':
        refetchSupplierFiles();
        queryClient.invalidateQueries([`tender-supplier-${subTab}-files-count`]);

        break;
      case 'group':
        refetchGroupFiles();
        queryClient.invalidateQueries([`tender-supplier-${subTab}-files-count`]);
          break;

      default:
        refetchGeneralFiles();
        refetchGeneralFilesCount();
        break;
    }
    setMarkedForDelete(null);
  };
  const handleMutation = () => {
    switch (selectedType) {
      case 'supplier':
        refetchSupplierFiles();
        queryClient.invalidateQueries([`tender-supplier-${subTab}-files-count`]);
        break;
      case 'group':
        refetchGroupFiles();
        queryClient.invalidateQueries([`tender-supplier-${subTab}-files-count`]);
          break;

      default:
        refetchGeneralFiles();
        refetchGeneralFilesCount();
        break;
    }

    if (markedForEdit) {
      setMarkedForEdit(null);
    }
  };

  const { mutateAsync: removeFile } = useRemoveFile(onFileRemoval);

  useTabReset();
  useSubTabReset();
  useRefetch();

  useEffect(() => {
    if (isFetchingFiles) {
      const timeoutId = setTimeout(() => setLoadingFiles(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetchingFiles]);

  const switchSubTab = (_, newSubTab) => setSubTab(newSubTab);

  let tabs = [{label: "General", count: generalFilesCount}];
  suppliers.forEach((supplier, index) => {
    tabs.push({label: supplier.company_type === 'supplier' ? supplier?.Company.Name : supplier.ConsortiumGroup.cg_name, count: suppliersFilesCount[index], value: `${supplier.tc_id}`})
  });

  useEffect(() => {
    setSubTab(currentSubtab);
  }, [currentSubtab]);

  if (currentTab !== filesTabName) return null;

  if (!account.super_admin && (!user.Company.is_premium || user.Company.subscription_level !== 'gold')) {
    return <FilesPremiumBanner count={total} />;
  }

  console.log("FilesTab");
  console.log(url);
  console.log(name);
  console.log(MimeType);


  return (
    <>
      <ImageViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('image/')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />

      <PDFViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('application/pdf')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />
      {account.super_admin && (
        <DeleteConfirmationModal
          title={'Remove file'}
          markedForDelete={markedForDelete}
          mutateAsync={() => removeFile({ tenderID: id, fileId: markedForDelete?.itemId, tcID: selectedType === 'supplier' ? selectedSupplier?.tc_id : null, cgId:  selectedType === 'group' ? selectedSupplier?.tc_id : null })}
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the file{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
      )}

      <Stack gap={3} height={1}>
        <>
          <DetailViewTabList
            value={subTab}
            onChange={switchSubTab}
            tabs={tabs}
            multi
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
            <Search fullWidth handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 270}}/>
            {account.super_admin && (
              <Box sx={{minWidth: 150, width: 200}}>
                <FileUploadModal handleSuccess={handleMutation} markedForEdit={markedForEdit} tcID={selectedSupplier?.tc_id} parentType={selectedType} />
              </Box>
            )}
          </Stack>

          <Divider />

          {isFetchingFiles && !loadingFiles ? 
          (
            <></>
          ) :
          (!isFetchingFiles || (isFetchingFiles && loadingFiles)) ?
          (
            <>
            {loadingFiles && (
              <Loader />
            )}

            {!loadingFiles && (
              <>
              <Stack flex={1} gap={1}>
                {files?.length === 0 && (
                  <NotFound
                    title="No Files Found"
                    caption={
                      search
                        ? 'Please try another search term'
                        : account.super_admin ? 'Click the "Upload File" button to begin' : ''
                    }
                    wrapperSx={{ mt: 19 }}
                  />
                )}

                {files?.length !== 0 &&
                  files.map((file) => (
                    <FileListItem
                      key={file.file_id}
                      handleFilePreview={() => setCurrentFilePreviewId(file.file_id)}
                      handleDownload={() => downloadFile({tenderID: id, fileId: file.file_id})}
                      markForDelete={() => setMarkedForDelete({ name: file.name, itemId: file.file_id })}
                      markForEdit={() => setMarkedForEdit({ name: file.name, fileId: file.file_id })}
                      tenderID={id}
                      {...file}
                    />
                  ))}
              </Stack>

              <Stack direction="row" justifyContent="center">
                <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
              </Stack>
            </>
            )}
          </>
          ) :
          (
            <></>
          )}
        </>
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      switch (selectedType) {
        case 'supplier':
          refetchSupplierFiles();
          break;
        case 'group':
          refetchGroupFiles();
            break;

        default:
          refetchGeneralFiles();
          break;
      }
    }, [page, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== filesTabName) {
        setPage(1);
        setSearch('');
        setSubTab('general');
      }
    }, [currentTab]);
  }

  function useSubTabReset() {
    useEffect(() => {
      setPage(1);
      setSearch('');
    }, [subTab]);
  }
};
