import React, { useEffect, useState } from 'react';

import { Modal } from 'components/Modal';
import { useParams } from 'react-router-dom';
import { useCreateFile } from '../api/createFile';
import { AddButton } from 'components/Button';
import { IconWrapper } from 'components/Icon';
import { Box, Button, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, TextField, Upload } from 'components/Form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { fileUploadSchema } from '../validation';
import { useUpdateFile } from '../api/updateFile';
import { Loader } from 'components/Loader';

const CustomEndIcon = () => (
  <IconWrapper
    icon="plus"
    sx={{
      height: 24,
      svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
    }}
  />
);

const getDefaultValues = () => ({
  name: '',
  file: null,
});

export const FileUploadModal = ({ handleSuccess, markedForEdit }) => {
  const { name, fileId } = markedForEdit || { name: undefined, fileId: undefined };
  const isUpdating = !!fileId;

  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const [ skipWatermark, setSkipWatermark] = useState(false)
  const handleSkipWatermarkChange = () => setSkipWatermark(!skipWatermark);

  const methods = useForm({
    defaultValues: isUpdating ? { name } : getDefaultValues(),
    resolver: useYupValidationResolver(fileUploadSchema(isUpdating)),
  });

  const { mutate: createNewFile, isLoading: isLoadingCreate } = useCreateFile(+id);
  const { mutate: updateFile, isLoading: isLoadingUpdate } = useUpdateFile();

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    methods.reset({ ...getDefaultValues() });
    setSkipWatermark(false);
    setOpen(false);
  };

  const onSuccess = () => {
    handleSuccess();
    handleClose();
  };

  const onSubmit = async (values) => {
    return isUpdating
      ? updateFile({ faId: id, fileId, ...values }, { onSuccess })
      : createNewFile({...values, skipWatermark}, { onSuccess });
  };

  const handleDropSingleFile = (acceptedFiles) => {
    const file = acceptedFiles[0];

    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    if (newFile) {
      methods.setValue('file', newFile, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (markedForEdit) {
      methods.reset({ name });
      setOpen(true);
    }
  }, [markedForEdit]);

  return (
    <>
      <AddButton
        fullWidth
        onClick={handleOpen}
        sx ={({ palette }) => ({
          svg: { mt: -0.5 },
          minWidth: 200,
          border: `1px solid ${palette.grey[200]}`,
        })}
        color='secondary'
      >
        Upload File
      </AddButton>
      <Modal title="Upload File" open={open} handleClose={handleClose}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack gap={3}>
            {isLoading && (
              <Loader style={{marginTop: !isUpdating ? "16vh" : "3vh", marginBottom: !isUpdating ? "16vh" : "3vh"}} />
            )}
            {!isLoading && (
              <>
              <TextField required fullWidth name="name" label="File Name" />
              {!isUpdating && (
                <>
                  <Upload name="file" onDrop={handleDropSingleFile} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={skipWatermark}
                        onChange={handleSkipWatermarkChange}
                      />
                    }
                    label="Skip Watermark"
                  />
                </>
              )}
              <Stack direction="row" gap={3} justifyContent="center">
                <Button variant="outlined" color="primary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Stack>
              </>
            )}
          </Stack>
        </FormProvider>
      </Modal>
    </>
  );
};
