import { axios } from 'lib/axios';
import { useQuery } from '@tanstack/react-query';

const getByIDRequest = async (id) => {
  return await axios.get(`/api/contacts/${id}`);
};

export const useUsersById = (id, config = {}) => {
  const configOptions = {select: ({ data }) => data, retry: false, ...config}
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => getByIDRequest(id),
    ...configOptions
  });
};