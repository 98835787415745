import qs from 'qs';
import { useQuery, useQueries } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getTendersSupplierFilesCount = (tenderID, tcID, companyID) => {
  return axios.get(
    `/api/tenders/${tenderID}/suppliers/${tcID}/files/count`, {headers: {"company_id": companyID}}
  );
};

export const useTendersSupplierFilesCount = (tenderID, tcID, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `tender-supplier-${tcID}-count`,
    () => getTendersSupplierFilesCount(tenderID, tcID, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}

export const useTendersSupplierFilesCountQueries = (tenderId, tcIDs, options = {}) => {
  const { user } = useAuth();
  const companyID = user?.Company?.company_id;

  // Use useQueries to create a query for each facId
  const queries = useQueries(
    tcIDs.map(tcID => ({
      queryKey: [`tender-supplier-${tcID}-files-count`, tcID],
      queryFn: () => getTendersSupplierFilesCount(tenderId, tcID, companyID),
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }))
  );

  return queries;
};
