import React, { useEffect } from 'react';

import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';


import { isoToDateString } from 'utils/dateFormatter';

import { useTenderCompanyCount } from '../api/getTendersCompanyCount';
import { useTenderFilesCount } from '../api/getTendersFilesCount';
import { useTenderSuppliers } from '../api/getTenderSuppliers';
import { useTendersSupplierFilesCountQueries } from '../api/getTendersSupplierFilesCount';

import { useAuth } from 'lib/auth';
import { paths } from 'routes';

export const TenderListItem = ({
  tender_id,
  tender_name: tenderName,
  ref_number,
  Status,
  created_on,
  edited_on,
  MainBuyer,
}) => {
  const { account } = useAuth();
  const {
    //@ts-ignore
    data: { count: buyersCount },
  } = useTenderCompanyCount(tender_id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount },
  } = useTenderCompanyCount(tender_id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useTenderFilesCount(tender_id);

  const {
    data: { suppliers },
  } = useTenderSuppliers(tender_id, {
    includeInactive: account.super_admin ? true : false,
    limit: 200,
    queryKey: [`tender-${tender_id}-list-files-suppliers`],
  });

  const facIds = suppliers.map(supplier => supplier.tc_id);
  const filesCountQueries = useTendersSupplierFilesCountQueries(tender_id, facIds);
  const allSuppliersFilesFetched = filesCountQueries.every(query => query.isFetched);

  const { suppliersFilesTotal, isSuppliersFilesTotalReady } = allSuppliersFilesFetched ? filesCountQueries.length === 0 ?
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: true }
  :
  filesCountQueries.reduce(
    (acc, query, index, array) => {
      const count = query.data?.count || 0;
      acc.suppliersFilesTotal += count;

      if (index === array.length - 1 && allSuppliersFilesFetched) {
        acc.isSuppliersFilesTotalReady = true;
      }

      return acc;
    },
    { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false }
  ) :
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false };

  const totalFiles = isSuppliersFilesTotalReady ? filesCount + suppliersFilesTotal : 0;

  return (
    <ListCard>
      <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={tenderName} />
      <Stack flex={{ md: 2, xl: 4 }} justifyContent="center">
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}`}
          color="inherit"
        >
          <Typography variant="body1" fontWeight={700}>
            {tenderName}
          </Typography>
      </Link>
      <Stack direction="row" gap={1}>
        <Typography variant="caption" fontWeight={700}>
          {ref_number}
        </Typography>
        <Divider
          orientation="vertical"
          sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
        />
        <Typography variant="caption" color="text.primary" fontWeight={700}>
          <Link
              component={RouterLink}
              to={`/app/${MainBuyer?.Type}s/${MainBuyer?.company_id.toString()}`}
              color="inherit"
            >
              {MainBuyer?.Name}
          </Link> 
          <Typography variant="caption" fontWeight={600}> ({MainBuyer?.VAT})</Typography>
        </Typography>
      </Stack>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.3}>
        <Typography variant="caption" fontWeight={700}>
          Buyers
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}?tab=buyers`}
          color="inherit"
        >
         <Typography variant="body2" fontWeight={700}>
            {buyersCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.35}>
        <Typography variant="caption" fontWeight={700}>
          Bidders
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}?tab=bidders`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {suppliersCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.3}>
        <Typography variant="caption" fontWeight={700}>
          Files
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}?tab=files`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {totalFiles}
          </Typography>
        </Link>
      </Stack>
      {account.super_admin && (
        <>
          <Stack justifyContent="center" flex={2}>
            <Typography variant="caption" fontWeight={700}>
              Created
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(created_on, true)}
            </Typography>
          </Stack>
          <Stack justifyContent="center" flex={2}>
            <Typography variant="caption" fontWeight={700}>
              Last Edit
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(edited_on, true)}
            </Typography>
          </Stack>
          <StatusComponent status={Status} />
        </>
      )}
    </ListCard>
  );
};
