import qs from 'qs';
import { useQuery, useQueries } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getFrameworkAgreementsSupplierFilesCount = (faId, facId, companyID) => {
  return axios.get(
    `/api/framework_agreements/${faId}/suppliers/${facId}/files/count`, {headers: {"company_id": companyID}}
  );
};

export const useFrameworkAgreementsSupplierFilesCount = (faId, facId, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `framework-agreement-supplier-${facId}-count`,
    () => getFrameworkAgreementsSupplierFilesCount(faId, facId, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}

export const useFrameworkAgreementsSupplierFilesCountQueries = (faId, facIds, options = {}) => {
  const { user } = useAuth();
  const companyID = user?.Company?.company_id;

  // Use useQueries to create a query for each facId
  const queries = useQueries(
    facIds.map(facId => ({
      queryKey: [`framework-agreement-supplier-${facId}-files-count`, facId],
      queryFn: () => getFrameworkAgreementsSupplierFilesCount(faId, facId, companyID),
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }))
  );

  return queries;
};