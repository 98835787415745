import React from 'react';
import { Box, Button, Divider, Stack, Typography, IconButton } from '@mui/material';
import { useCallback, useState } from 'react';

import { AddButton } from 'components/Button';
import { ListCard, PaperCard } from 'components/Card';
import { CardAutocomplete } from 'components/Form';
import { IconWrapper } from 'components/Icon';

import { ShadowCompany } from 'features/companies/components/ShadowCompany';
import { getCompanies } from '../api/getCompanies';
import { useTender } from '../api/getTenderById';
import { useParams } from 'react-router-dom';
import { useAddCompaniesToTender } from '../api/addCompanyToTender';
import { useUpdateConsortiumGroup } from '../api/updateConsortiumGroup';
import { useConsortiumGroup } from '../api/getConsortiumGroupById';
import { ImageAvatar } from 'components/Avatar';

const ItemCard = ({
  Name: name,
  VAT: vatNumber,
  Description: description,
  companyType,
  square_logo_url,
  alreadySelected,
  alreadyAdded,
  company_id,
  ...rest
}) => {
  if (alreadyAdded.some(item => companyType === 'buyer' ? item.company_id === company_id : item.company.company_id === company_id )) {
    return null;
  }

  if (alreadySelected.some(item => item.company_id === company_id)) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{ img: { borderRadius: 1, width: 20, height: 20 } }}
      {...rest}
    >
      <ImageAvatar img={square_logo_url || ''} name={name} searchResult />
      <Typography variant="body1" fontWeight="bold">
        {name} ({vatNumber})
      </Typography>
    </Stack>
  );
};
const CustomEndIcon = () => (
  <IconWrapper
    icon="plus"
    sx={{
      height: 24,
      svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
    }}
  />
);

export const AddCompany = ({ type = 'buyer', refetch, alreadyAdded, label = '' }) => {
  const { id: tenderID, cgId } = useParams();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const { data: tender } = useTender(tenderID);
  const {
    data: { cg_name },
  } = useConsortiumGroup(tenderID, cgId, {
    queryOptions: { enabled: !!cgId },
  });

  const { mutateAsync: updateTenderCompanies } = useAddCompaniesToTender();
  const { mutateAsync: updateConsortiumGroupCompanies } = useUpdateConsortiumGroup();

  const handleOpenModal = useCallback(() => setModalOpen(true), []);
  const handleCloseModal = useCallback(() => setModalOpen(false), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setSelected([]);
  }, []);

  const onUpdate = () => {
    refetch();
    handleClose();
  };

  const handleSave = () => {
    if (cgId === undefined)
      updateTenderCompanies({
        tenderID: tenderID,
        companies_ids: selected.map(({ company_id }) => +company_id),
        company_type: type,
      })
        .then(onUpdate)
        .catch(() => null);
    else
      updateConsortiumGroupCompanies({
        cgId,
        tenderID,
        addedSuppliers: selected.map(({ company_id }) => +company_id),
      })
        .then(onUpdate)
        .catch(() => null);
  };

  const removeSelected = (id) =>
    setSelected((selected) => selected.filter(({ company_id }) => company_id !== id));

  const addCompany = (value) => {
    if (value) setSelected((previousSelected) => [...previousSelected, value]);
  };

  const addShadowSupplier = (shadowSupplier) => {
    setSelected((previousSelected) => [...previousSelected, shadowSupplier]);
  };

  label = label.length > 0 ? label : type;

  return (
    <>
      <AddButton
        fullWidth
        onClick={() => setOpen(true)}
        sx ={({ palette }) => ({
          svg: { mt: -0.5 },
          minWidth: 200,
          border: `1px solid ${palette.grey[200]}`,
        })}
        color='secondary'
      >
        Add {label[0].toUpperCase()}{label.slice(1)}s
      </AddButton>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          display: open ? 'block' : 'none',
          zIndex: 40,
        }}
      >
        <ShadowCompany
          open={modalOpen}
          handleClose={handleCloseModal}
          addToList={addShadowSupplier}
          type={type}
          label={type === 'supplier' ? 'bidder' : ''}
        />
        <Stack width={1} height={1}>
          <PaperCard
            sx={{
              background: '#fff',
              width: '100%',
              py: 4,
              px: 8,
              border: 0,
              borderRadius: 0,
              boxShadow: 26,
              display: 'flex',
              boxSizing: 'border-box',
            }}
          >
            <Stack direction="row" width={1} alignItems="center" justifyContent="space-between">
              <Typography variant="h3">
                Add {label}s to ‘
                {cgId ? cg_name : tender?.tender_name || 'a tender'}’
              </Typography>
              <Stack direction="row" gap={2}>
                <Button
                  size="medium"
                  onClick={handleClose}
                  variant="contained"
                  sx={({ palette }) => ({
                    width: 82,
                    boxShadow: 25,
                    color: palette.secondary.contrastText,
                    backgroundColor: '#fff',
                    ":hover" : {
                      backgroundColor: palette.secondary.dark,
                    }
                  })}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selected.length}
                  size="medium"
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  sx={{ width: 67 }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </PaperCard>

          <Stack direction="row" flex={1}>
            <Stack mt={7} px={8} gap={3} flex={1}>
              <Typography variant="h2">Select {label}s</Typography>
              <CardAutocomplete
                label={`Existing ${label}s`}
                fullWidth
                onChange={addCompany}
                queryFunction={(searchTerm) => getCompanies(type, searchTerm)}
                ItemCard={(props) => <ItemCard companyType={type} {...props} alreadyAdded={alreadyAdded} alreadySelected={selected} />}
                handleNotFoundButtonClick={handleOpenModal}
                optionLabelName="company_id"
                companyType={`${label[0].toUpperCase()}${label.slice(1)}`}
              />

              <AddButton color="secondary" onClick={handleOpenModal}>Create Shadow {label[0].toUpperCase()}{label.slice(1)}</AddButton>
            </Stack>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'grey.500' }} />

            <Stack mt={7} gap={3} flex={1} px={8}>
              <Typography variant="h2">
                Added {label}s {selected.length ? `(${selected.length})` : ''}
              </Typography>

              {selected.length !== 0 && (
                <Stack gap={1}>
                  <Typography>These {label}s can shop from this Tender</Typography>

                  {selected.map(({ Name: name, VAT: vatNumber, Type: type, company_id }, index) => (
                    <ListCard key={index} sx={{py:1}}>
                      <Stack flex={1} direction="row">
                        <Stack flex={4} alignItems="flex-start">
                          <Typography variant="body1" fontWeight="bold">
                            {name}
                          </Typography>
                          <Stack direction="row" gap={1}>
                            <Typography variant="caption" fontWeight={600}>
                              {vatNumber}
                            </Typography>
                          </Stack>
                        </Stack>
                        <IconButton
                          onClick={() => removeSelected(company_id)}
                          sx={{
                            "&:hover" : {
                              backgroundColor: "#fff",
                            },
                          }}
                        >
                          <IconWrapper
                            icon="trash"
                            sx={({ palette }) => ({
                              svg: {
                                width: 24,
                                height: 24,
                                mt:-1.5,
                                stroke: palette.grey[500],
                                color: palette.grey[500],
                              },
                            })}
                          />
                        </IconButton>
                      </Stack>
                    </ListCard>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
