import { axios } from 'lib';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

const updateUserVisibility= ({ userID, visible }) => {
  try {
    return axios.put(
      `/api/contacts/${userID}/visible`,
      JSON.stringify({ visible: visible })
    );
  } catch (error) {
    return error;
  }
};

export const useUpdateUserVisibility = (refetch = null) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateUserVisibility,
    onSuccess: () => {
      enqueueSnackbar('Contact\'s visibility successfully updated', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
