import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const handleUserRequest = ({ companyID, userID, action }) => {
  return axios.put(`/api/companies/${companyID}/contacts/${userID}/${action}`);
};

export const useHandleUserRequest = (onUserAcceptance, message) => {
  const { enqueueSnackbar } = useSnackbar();
  const snackMessage = message;

  return useMutation({
    mutationFn: handleUserRequest,
    onSuccess: () => {
      enqueueSnackbar(snackMessage, {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      onUserAcceptance();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
