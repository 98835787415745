import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup, Stack, Typography, Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';

import { useSubscriptions } from '../api/getAllSubscriptions';
import { SubscriptionsListItem } from '../components/SubscriptionsListItems';
//import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';

// import { AddCompanyForm } from '../components/AddCompanyForm';

export const List = ({ companyType }) => {
  const { account } = useAuth();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const {
    data: { subscriptions, total },
    refetch: refetchSubscritions,
  } = useSubscriptions({
    page: page - 1,
    // onSettled: () => setLoading(false),
    queryOptions: { onSettled: () => setLoading(false), enabled: false },
  });

  const handlePageChange = (_, page) => setPage(page);

  useSubscriptionsRefetch();

  const labelName = "Subscriptions";

  return (
    <>
    {account.super_admin && (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[
              { name: '', to: "" /* paths.subscriptions */ },
              { name: `${labelName} ${subscriptions && `(${total})`}`},
            ]}
          />
        </Stack>

        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            {/* <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 350 }} />
            {account.super_admin && (
              <>
                <FormGroup sx={{ flex: '1 1 300px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={includeInactive}
                        onChange={handleIncludeInactiveChange}
                      />
                    }
                    label="Include inactive"
                  />
                </FormGroup>
                <Box sx={{minWidth: 150, width: 200}}>
                  <AddCompanyForm type={companyType} refetch={refetchCompanies} />
                </Box>
              </>
            )} */}
          </Stack>

          {!subscriptions && (
            <Stack
              height="100%"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h5">No {labelName} added</Typography>
              {/* {account.super_admin && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    letterSpacing: '-0.14px',
                  }}
                >
                  Click the "Add Company" button to begin
                </Typography>
              )} */}
            </Stack>
          )}

          {subscriptions && (
            <Stack
              flex={1}
              mt={2}
              py={2}
              bgcolor="background.paper"
              borderTop="1px solid"
              borderColor="border.main"
              gap={1}
            >
              {subscriptions.length !== 0 &&
                subscriptions.map((item) => (
                  <SubscriptionsListItem key={item.id} {...item} />
                ))}
            </Stack>
          )}
          {subscriptions && subscriptions.length !== 0 && (
            <Stack direction="row" justifyContent="center">
              <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
    )}
    </>
  );

  function useSubscriptionsRefetch() {
    useEffect(() => {
      refetchSubscritions();
    }, [page]);
  }
};
