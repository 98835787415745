import * as yup from 'yup';

export const mainInfoSchema = yup.object().shape({
  tenderName: yup.string().required('Tender name is required field'),
  mainBuyer: yup.string().required('Main buyer is required field'),
  refNumber: yup.string().required('Reference number is required field'),
});

export const mainInfoSchemaFull = (step, isShadow) =>
  (isShadow
    ? {
        0: yup.object().shape({
          tenderName: yup.string().required('Tender name is required field'),
          mainBuyer: yup.string().optional(),
          refNumber: yup.string().required('Reference number is required field'),
          mainBuyerName: yup.string().when('mainBuyer', {
            is: (mainBuyer) => !mainBuyer,
            then: (schema) => schema.required('Name is required field'),
            otherwise: (schema) => schema.optional(),
          }),
          VAT: yup.string().when('mainBuyer', {
            is: (mainBuyer) => !mainBuyer,
            then: (schema) => schema.required('VAT is required field'),
            otherwise: (schema) => schema.optional(),
          }),
          vatNumberConfirmation: yup.string().when('mainBuyer', {
            is: (mainBuyer) => !mainBuyer,
            then: (schema) =>
              schema
                .required('Please repeat VAT')
                .oneOf([yup.ref('VAT')], "VAT number didn't match"),
            otherwise: (schema) => schema.optional(),
          }),
        }),
        1: yup.object().shape({
          tenderName: yup.string().required('Tender name is required field'),
          mainBuyer: yup.string().optional(),
          refNumber: yup.string().required('Reference number is required field'),
          mainBuyerName: yup.string().when('mainBuyer', {
            is: (mainBuyer) => !mainBuyer,
            then: (schema) => schema.required('Required string'),
            otherwise: (schema) => schema.optional(),
          }),
          VAT: yup.string().when('mainBuyer', {
            is: (mainBuyer) => !mainBuyer,
            then: (schema) => schema.required('Required string'),
            otherwise: (schema) => schema.optional(),
          }),
          vatNumberConfirmation: yup.string().when('mainBuyer', {
            is: (mainBuyer) => !mainBuyer,
            then: (schema) =>
              schema
                .required('Please repeat VAT')
                .oneOf([yup.ref('VAT')], "VAT number didn't match"),
            otherwise: (schema) => schema.optional(),
          }),
          postingDate: yup.date().required('Posting date is required field'),
          lastOfferDate: yup.date().required('Last offer date is required field'),
          startDate: yup.date().required('Start date is required field'),
          endDate: yup.date().required('End date is required field'),
        }),
      }
    : {
        0: yup.object().shape({
          tenderName: yup.string().required('Tender name is required field'),
          mainBuyer: yup.string().required('Main buyer is required field'),
          refNumber: yup.string().required('Reference number is required field'),
        }),
        1: yup.object().shape({
          tenderName: yup.string().required('Tender name is required field'),
          mainBuyer: yup.string().required('Main buyer is required field'),
          refNumber: yup.string().required('Reference number is required field'),
          postingDate: yup.date().required('Posting date is required field'),
          lastOfferDate: yup.date().required('Last offer date is required field'),
          startDate: yup.date().required('Start date is required field'),
          endDate: yup.date().required('End date is required field'),
        }),
      })[step];

// step === 0
//   ? yup.object().shape({
//       tenderName: yup.string().required('Tender name is required field'),
//       mainBuyer: yup.string().required('Main buyer is required field'),
//       refNumber: yup.string().required('Reference number is required field'),
//       Name: yup.string().when('mainBuyer', {
//         is: (mainBuyer) => !mainBuyer,
//         then: (schema) => schema.required('Required string'),
//         otherwise: (schema) => schema.optional(),
//       }),
//       VAT: yup.string().when('mainBuyer', {
//         is: (mainBuyer) => !mainBuyer,
//         then: (schema) => schema.required('Required string'),
//         otherwise: (schema) => schema.optional(),
//       }),
//       vatNumberConfirmation: yup.string().when('mainBuyer', {
//         is: (mainBuyer) => !mainBuyer,
//         then: (schema) =>
//           schema.required('Please repeat VAT').oneOf([yup.ref('VAT')], "VAT number didn't match"),
//         otherwise: (schema) => schema.optional(),
//       }),
//     })
//   : yup.object().shape({
//       tenderName: yup.string().required('Tender name is required field'),
//       mainBuyer: yup.string().required('Main buyer is required field'),
//       refNumber: yup.string().required('Reference number is required field'),
//       description: yup.string().required('Description is a required field'),
//       startDate: yup.date().required('Start date is required field'),
//       endDate: yup.date().required('End date is required field'),
//     });
export const additionalDetailsSchema = yup.object().shape({
  tenderName: yup.string().required('Tender name is required field'),
  postingDate: yup.date().required('Posting date is required field'),
  lastOfferDate: yup.date().required('Last offer date is required field'),
  startDate: yup.date().required('Start date is required field'),
  endDate: yup.date().required('End date is required field'),
});
