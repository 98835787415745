import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup, Stack, Typography, Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';

import { useUsers } from '../api/getAllUers';
import { UsersListItem } from '../components/UsersListItems';
//import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';
import { Loader } from 'components/Loader';

export const List = ({ companyType }) => {
  const { account } = useAuth();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [includeInactive, setIncludeInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const {
    data: { users, total },
    refetch: refetchUsers,
    isFetching,
  } = useUsers({
    search,
    includeInactive,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      }
    },
  });

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const handlePageChange = (_, page) => setPage(page);

  useUsersRefetch();

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  return (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[{ name: '', to: paths.users }, { name: `Contacts${total > 0 ? ` (${total})` : ''}` }]}
          />
        </Stack>

        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />
              <FormGroup sx={{ flex: '1 1 300px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={includeInactive}
                      onChange={handleIncludeInactiveChange}
                    />
                  }
                  label="Include inactive"
                />
              </FormGroup>
          </Stack>
          {isFetching && !loading ? 
          (
            <></>
          ) :
          (!isFetching || (isFetching && loading)) ?
          (
            <>
            {loading && (
              <Loader />
            )}

            {!loading && (
              <>
              <Stack
                flex={1}
                mt={2}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
              >
                {isFetching && (
                  <Loader />
                )}

                {!isFetching && (
                  <>
                  {(!users || users.length === 0) && (
                    <NotFound
                      wrapperSx={{ mt: 19 }}
                      title={`No users found`}
                      // caption={account.super_admin && 'Click the "Invite Contact" button to begin'}
                    />
                  )}
                  {users.length !== 0 &&
                    users.map((item) => <UsersListItem key={item.user_id} {...item} />
                  )}
                  </>
                )}
              </Stack>
              <Stack direction="row" justifyContent="center">
                <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
              </Stack>
              </>
            )}
            </>
          ) :
          (
            <></>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useUsersRefetch() {
    useEffect(() => {
      refetchUsers();
    }, [page, includeInactive, search]);
  }
};
