import { axios } from 'lib';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  queryKey: ['file-preview'],
  queryOptions: {},
};

const previewFile = async (faId, fileId, companyID, facId = null) => {
  const url = facId
    ? `/api/framework_agreements/${faId}/suppliers/${facId}/files/${fileId}`
    : `/api/framework_agreements/${faId}/files/${fileId}`;
  const {
    data: { data },
  } = await axios.get(url, {headers: {"company_id": companyID}});

  return data;
};

export const useFilePreview = ({faId, fileId, facId = null, queryParams = {}}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return useQuery([...queryKey, fileId], () => previewFile(faId, fileId, user.Company.company_id, facId), {
    refetchInterval: 5 * 60 * 1000,
    initialData: { url: '', name: '', file_ext: '', mime_type: '' },
    enabled: !!fileId,
    select: ({ url, name, file_ext: FileExt, mime_type: MimeType }) => ({ url, name, FileExt, MimeType }),
    ...queryOptions,
  });
};
