import React from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { isoToDateString } from 'utils/dateFormatter';

import { useRelatedCompaniesCount } from '../api/getRelatedCompaniesCount';
import { useCompanyFrameworkAgreementsCount } from '../api/getCompanyFrameworkAgreementsCount';
import { useCompanyUsersCount } from '../api/getCompanyUsersCount';
import { useCompanyTendersCount } from '../api/getRelatedTendersCount';
import { useCompanyDPSCount } from '../api/getRelatedDPSCount';
import { ReactComponent as RegisteredIcon } from 'assets/Registered.svg';

import { useAuth } from 'lib/auth';

export const CompaniesListItem = ({
  company_id,
  Name,
  VAT,
  Status,
  created_on,
  edited_on,
  companyType,
  square_logo_url,
  is_shadow,
}) => {
  const { account } = useAuth();
  const {
    //@ts-ignore
    data: { count: relatedCompaniesCount },
  } = useRelatedCompaniesCount(company_id, companyType);
  const {
    //@ts-ignore
    data: { count: activeFACount },
  } = useCompanyFrameworkAgreementsCount(company_id, {expired: false});
  const {
    //@ts-ignore
    data: { count: expiredFACount },
  } = useCompanyFrameworkAgreementsCount(company_id, {expired: true});
  const {
    //@ts-ignore
    data: { count: usersCount },
  } = useCompanyUsersCount(company_id);
  const {
    //@ts-ignore
    data: { count: activeTendersCount },
  } = useCompanyTendersCount(company_id, {expired: false});
  const {
    //@ts-ignore
    data: { count: expiredTendersCount },
  } = useCompanyTendersCount(company_id, {expired: true});
  const {
    //@ts-ignore
    data: { count: activeDPSCount },
  } = useCompanyDPSCount(company_id, {
    expired: false,
    queryOptions: {
      enabled: companyType === 'buyer',
    },
  });
  const {
    //@ts-ignore
    data: { count: expiredDPSCount },
  } = useCompanyDPSCount(company_id, {
    expired: true,
    queryOptions: {
      enabled: companyType === 'buyer',
    },
  });

  const tabName = companyType === 'buyer' ? 'suppliers' : 'customers';

  return (
    <ListCard>
      <ImageAvatar img={square_logo_url || ''} name={Name}/>
      <Stack flex={{ md: 3, lg: 4 }} justifyContent="center">
        <Link
          component={RouterLink}
          to={`/app/${companyType}s/${company_id.toString()}`}
          color="inherit"
        >
          <Stack direction={'row'} gap={1}>
            <Typography variant="body1" fontWeight={700}>
              {Name}
            </Typography>
            {account.super_admin && !is_shadow && (
              <RegisteredIcon width={10}/>
            )}
          </Stack>
        </Link>
        <Stack direction="row" gap={1}>
          <Typography variant="caption" fontWeight={600}>
            {VAT}
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1.2 : 0.65}>
        <Typography variant="caption" fontWeight={700}>
          Agreements
        </Typography>
        <Link
          component={RouterLink}
          to={`/app/${companyType}s/${company_id.toString()}?tab=agreements`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {account.super_admin ? `${activeFACount} (${expiredFACount})` : activeFACount + expiredFACount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.5}>
        <Typography variant="caption" fontWeight={700}>
          {companyType === 'buyer' ? 'Tenders' : 'Bids'}
        </Typography>
        <Link
          component={RouterLink}
          to={`/app/${companyType}s/${company_id.toString()}?tab=${companyType === 'buyer' ? 'tenders' : 'bids'}`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {account.super_admin ? `${activeTendersCount} (${expiredTendersCount})` : activeTendersCount + expiredTendersCount}
          </Typography>
        </Link>
      </Stack>
      {companyType === 'buyer' && (
        <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.5}>
          <Typography variant="caption" fontWeight={700}>
            DPS
          </Typography>
          <Link
            component={RouterLink}
            to={`/app/${companyType}s/${company_id.toString()}?tab=dps`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {account.super_admin ? `${activeDPSCount} (${expiredDPSCount})` : activeDPSCount + expiredDPSCount}
            </Typography>
          </Link>
        </Stack>
      )}
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.5}>
        <Typography variant="caption" fontWeight={700}>
          {companyType === 'buyer' ? 'Suppliers' : 'Customers'}
        </Typography>
        <Link
          component={RouterLink}
          to={`/app/${companyType}s/${company_id.toString()}?tab=${tabName}`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {relatedCompaniesCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.4}>
        <Typography variant="caption" fontWeight={700}>
          Contacts
        </Typography>
        {usersCount > 0 ? (
          <Link
            component={RouterLink}
            to={`/app/${companyType}s/${company_id.toString()}?tab=contacts`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {`${usersCount}`}
            </Typography>
          </Link>
        ) : (
          <Typography variant="body2" fontWeight={700}>
            {`${usersCount}`}
          </Typography>
        )}
      </Stack>
        {account.super_admin && (
        <>
          <Stack justifyContent="center" flex={1.5}>
            <Typography variant="caption" fontWeight={700}>
              Registered
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(created_on, false)}
            </Typography>
          </Stack>

          <Stack justifyContent="center" flex={1.5}>
            <Typography variant="caption" fontWeight={700}>
              Last Edit
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(edited_on, false)}
            </Typography>
          </Stack>
          <StatusComponent status={Status} />
        </>
      )}
    </ListCard>
  );
};
