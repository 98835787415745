import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { paths } from 'routes';

import { useFrameworkAgreement } from '../api/getFrameworkAgreementById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useFrameworkAgreementCompanyCount } from '../api/getFrameworkAgreementCompanyCount';
import { useFrameworkAgreementFilesCount } from '../api/getFrameworkAgreementFilesCount';
import { useFrameworkAgreementSuppliers } from '../api/getFrameworkAgreementSuppliers';
import { useFrameworkAgreementsSupplierFilesCountQueries } from '../api/getFrameworkAgreementsSupplierFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';
import { Loader } from 'components/Loader';

export const DetailsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get('tab') || 'details';
  const [ loading, setLoading ] = useState(false);

  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(currentTab);
  const [timer, setTimer] = useState(null);

  const { data: frameworkAgreement, refetch: refetchFrameworkAgreement, error, isError, isLoading: agreementLoading, isFetched: isFetched1 } =
    useFrameworkAgreement(id);
  const {
    //@ts-ignore
    data: { count: buyersCount, isLoading: buyersCountLoading, isFetched: isFetched2 },
    refetch: refetchBuyersCount,
  } = useFrameworkAgreementCompanyCount(id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount, isLoading: suppliersCountLoading, isFetched: isFetched3 },
  } = useFrameworkAgreementCompanyCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount, isLoading: filesCountLoading, isFetched: isFetched4 },
  } = useFrameworkAgreementFilesCount(id);

  const {
    data: { suppliers },
    isLoading: isLoadingSuppliers,
    isFetched: isFetched5,
  } = useFrameworkAgreementSuppliers(id, {
    includeInactive: account.super_admin ? true : false,
    limit: 200,
    queryKey: [`framework-agreement-${id}-files-suppliers`],
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
    },
  });

  const facIds = suppliers.map(supplier => supplier.fac_id);
  const filesCountQueries = useFrameworkAgreementsSupplierFilesCountQueries(id, facIds);
  const suppliersFilesCount = filesCountQueries.map(query => query.data?.count || 0);
  const isLoadingSuppleirsFilesCount = filesCountQueries.some(query => query.isLoading);
  const allSuppliersFilesFetched = filesCountQueries.every(query => query.isFetched);

  const { suppliersFilesTotal, isSuppliersFilesTotalReady } = allSuppliersFilesFetched ? filesCountQueries.length === 0 ?
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: true }
  :
  filesCountQueries.reduce(
    (acc, query, index, array) => {
      const count = query.data?.count || 0;
      acc.suppliersFilesTotal += count;

      if (index === array.length - 1 && allSuppliersFilesFetched) {
        acc.isSuppliersFilesTotalReady = true;
      }

      return acc;
    },
    { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false }
  ) :
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false };

  const switchTab = (_, newTab) => {
    navigate(`?tab=${newTab}`, { replace: true });
  };

  const isLoading = agreementLoading || buyersCountLoading || suppliersCountLoading || filesCountLoading || isLoadingSuppliers || isLoadingSuppleirsFilesCount;
  const isFetched = isFetched1 || isFetched2 || isFetched3 || isFetched4 || isFetched5 || allSuppliersFilesFetched;

  const totalFiles = isFetched && isSuppliersFilesTotalReady ? filesCount + suppliersFilesTotal : 0; 

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }

  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  if (!loading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          !loading && isError ? (
            <PremiumBanner message={`This Agreement is only available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreement} currentLevel={user.Company.subscription_level} />
          ) : (
            <Stack height="100%">
              <BreadCrumbs
                items={[
                  // { name: 'Delegon Admin', to: paths.companyProfile },
                  { name: 'Framework Agrements', to: paths.frameworkAgreement },
                  { name: frameworkAgreement?.fa_name ?? 'Details View' },
                ]}
              />
              <Box
                height={70}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Stack>
                  {account.super_admin && (
                    <Divider
                      sx={({ palette }) => ({
                        height: 5,
                        width: 30,
                        bgcolor:
                          frameworkAgreement?.Status === 'active'
                            ? palette.success.light
                            : palette.error.light,
                        borderRadius: 1,
                      })}
                    />
                  )}
                  <Typography variant="h3">{frameworkAgreement?.fa_name || ''}</Typography>
                </Stack>

                {account.super_admin && (
                  <AccordionButton label="Actions" sx={{ width: 130 }}>
                    <Stack gap={0.5}>
                      <EditForm frameworkAgreement={frameworkAgreement} refetch={refetchFrameworkAgreement} />

                      <StatusToggleButton
                        refetch={refetchFrameworkAgreement}
                        status={frameworkAgreement?.Status}
                      />
                    </Stack>
                  </AccordionButton>
                )}
              </Box>

              <DetailViewTabList
                value={tab}
                onChange={switchTab}
                tabs={[
                  { label: 'Details' },
                  { label: 'Buyers', count: buyersCount },
                  { label: 'Suppliers', count: suppliersCount },
                  { label: 'Files', count: totalFiles },
                ]}
              />

              <Stack
                px={4}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
                height="100%"
              >
                {error && (
                  <PremiumBanner message={`This Agreement is only available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreement} currentLevel={user.Company.subscription_level} />
                  // <div>{error?.message}</div>
                )}
                {!error && (
                  <>
                {/* {tab === "details" && ( */}
                  <DetailsTab currentTab={tab} frameworkAgreement={frameworkAgreement} />
                {/* )} */}

                {/* {tab === "buyers" && ( */}
                  <BuyersTab
                    currentTab={tab}
                    refetchBuyersCount={refetchBuyersCount}
                  />
                {/* )} */}

                {/* {tab === "suppliers" && ( */}
                  <SuppliersTab currentTab={tab} />
                {/* )} */}

                {/* {tab === "files" && ( */}
                  <FilesTab
                    currentTab={tab}
                    suppliers={suppliers}
                    suppliersFilesCount={suppliersFilesCount}
                    generalFilesCount={filesCount}
                  />
                {/* )} */}
                </>
              )}
              </Stack>
            </Stack>
          )
        )
      )}
    </ContentLayout>
  );
};
