import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const extractExtension = (name = '') => {
  const extensionDotIndex = name
    .split('')
    .reverse()
    .findIndex((char) => char === '.');

  if (!name || extensionDotIndex === -1) return null;

  return name.slice(-1 * extensionDotIndex);
};

const createFile = async ({faId, facId = null, cgId = null, name, file, skipWatermark }) => {
  let url = `/api/framework_agreements/${faId}/files`;
  let parentType = 'framework_agreement';
  if (facId) {
    url = `/api/framework_agreements/${faId}/suppliers/${facId}/files`;
    parentType = 'supplier';
  }

  if (cgId) {
    url = `/api/framework_agreements/${faId}/consortium_groups/${cgId}/files`;
    parentType = 'consortium_group';
  }
  const formData = new FormData();

  file.fileName = file.name;

  formData.append('name', name);
  formData.append('file', file);
  formData.append('parent_id', faId);
  formData.append('mime_type', file.type);
  formData.append('file_ext', extractExtension(file.name));
  formData.append('last_updated_user_id', '1');
  formData.append('parent_type', parentType);
  formData.append('skip_watermark', skipWatermark);

  const { data } = await axios.post(url, formData);

  return data;
};

export const useCreateFile = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    //@ts-ignore
    mutationFn: createFile,
    onSuccess: () => enqueueSnackbar('File succesfully uploaded', { variant: 'success' }),
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
